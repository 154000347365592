import React from 'react';
import {generateID} from '../../tools';

const CheckboxItem = ({data, name, onChange})=> {
    const id = generateID();
    return(
        <div className="checkbox-group__item">
            <input type="checkbox" id={id} name={name} value={data.value} onChange={onChange} />
            <label htmlFor={id}>{data.label}</label>
        </div>
    )
}

export default CheckboxItem;