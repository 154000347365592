import React from 'react';

const TextArea = ({label, name, onChange}) => {
    return(
        <div className="field textarea">
            <label className="field__label">{label}</label>
            <textarea rows={5} name={name} onChange={onChange} />
        
        </div>  
    )
}

export default TextArea;