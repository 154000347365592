import React, {Component} from 'react';
import Form from './components/Form/Form';
import Preview from './components/Preview';
import COLORS from './datas/colors';
import domtoimage from 'dom-to-image';
import * as htmlToImage from 'html-to-image';
import {toPng, toJpeg, toBlob, toPixelData, toSvg} from 'html-to-image';
import jsPDF from 'jspdf';
import CAPACITIES from "./datas/capacities";


class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            category: 0,
            producer: '',
            name: null,
            year: null,
            appellation: '',
            quote: null,
            capacity: CAPACITIES[0].value,
            price: null,
            wineColor: COLORS[0].value,

            labels: []
        }
    }

    convertToJpg(doc) {
        const scale = 3;

        const style = {
            transform: `scale(${scale})`,
            transformOrigin: 'top left',
            width: `${doc.offsetWidth}px`,
            height: `${doc.offsetHeight}px`
        }

        const param = {
            height: doc.offsetHeight * scale,
            width: doc.offsetWidth * scale,
            quality: 1,
            style
        }

        return domtoimage.toJpeg(doc, param)

    }

    convertToPng(doc) {
        return domtoimage.toPng(doc)
    }

    handlePreview = () => {
        let doc = document.getElementById('preview');
        const pdf = new jsPDF({
            orientation: "portrait",
            unit: "mm",
            format: [210, 297]
        });



        this.convertToJpg(doc)
            .then(data => {
                pdf.addImage(data, 'JPEG', 0, 0, 210, 297,'','NONE');
                //pdf.addImage(data, 'JPEG', 148.7, 0, 148.3, 210,'', 'NONE');

                pdf.save('download.pdf');
            })
    }

    handleChange = (e) => {
        if (e.target.name === 'labels') {
            let labels = [...this.state.labels];
            if (e.target.checked) {
                labels.push(e.target.value)
            } else {
                labels = labels.filter(i => i !== e.target.value);
            }
            this.setState({
                labels
            })
        } else {
            this.setState({
                [e.target.name]: e.target.value
            })
        }



    }

    render() {

        const {name} = this.state;
        return (
            <div className="container">

                <Form onChange={this.handleChange}
                      capacity={this.state.capacity}
                      wineColor={this.state.wineColor}
                      handleClick={this.handlePreview}
                      name={this.state.name}
                      price={this.state.price}
                />

                <Preview {...this.state}  />





            </div>
        );
    }
}

export default App;
