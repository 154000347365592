import React from 'react';

import COLORS from '../../datas/colors';
import CATEGORIES from '../../datas/categories';
import PASTILLES from '../../datas/pastilles';
import CAPACITIES from "../../datas/capacities";

import Input from './Input';
import Radios from './Radios';
import Select from './Select';
import TextArea from './TextArea';
import Checkboxes from './Checkboxes';

const Form = ({onChange, capacity, wineColor, handleClick, name, price}) => {


    return (
        <form className="form">
            <Select
                label="Catégorie"
                name="category"
                onChange={onChange}
                datas={CATEGORIES}
                withDots
            />
            <TextArea
                label="Nom du domaine"
                name="producer"
                onChange={onChange}
            />

            <TextArea
                label="Nom de la cuvée"
                name="name"
                onChange={onChange}
            />
            <Input onChange={onChange} label="Millésime" name="year"/>
            <Input onChange={onChange} label="Appellation" name="appellation"/>

            <TextArea
                label="Citation"
                name="quote"
                onChange={onChange}
            />
            <Input
                onChange={onChange}
                label="Prix"
                name="price"
                placeholder="4.30"
            />
            <Radios
                datas={CAPACITIES}
                selectedRadio={capacity}
                label="Capacité"
                name="capacity"
                onChange={onChange}/>

            <Checkboxes
                datas={PASTILLES}
                label="Pastilles"
                name="labels"
                onChange={onChange}
            />

            <Radios
                datas={COLORS}
                selectedRadio={wineColor}
                label="Couleur"
                name="wineColor"
                onChange={onChange}
            />
            { name !== null && price !== null && name.length > 0 && price.length > 0 && <button type="button" className="btn btn-primary" onClick={handleClick}>Télécharger</button> }
        </form>
    )
}

export default Form;