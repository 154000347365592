const PASTILLES = [
    {
        label : 'BIO',
        value: '/img/logo-bio.png'
    },
    {
        label : 'Biodynamie',
        value: '/img/logo-biodynamie.png'
    },
    {
        label : 'Coup de coeur',
        value: '/img/logo-coupdecoeur.png'
    },
    {
        label : 'Exclusivité',
        value: '/img/logo-exclusivite.png'
    },
    {
        label : 'HVE',
        value: '/img/logo-hve.png'
    },
    {
        label : 'Nature',
        value: '/img/logo-nature.png'
    },
    {
        label : 'Nouveau',
        value: '/img/logo-nouveau.png'
    },
    {
        label : 'Sans soufre ajouté',
        value: '/img/logo-sans-soufre-ajoute.png'
    },
    {
        label : 'Vin medaillé',
        value: '/img/logo-vin-medaille.png'
    }
];

export default PASTILLES; 