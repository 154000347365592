import React from 'react';
import RadioItem from './RadioItem'

const Radios = ({datas, name, label, onChange, selectedRadio}) => {
console.log(selectedRadio)
    return (
        <div className="field radios">
            <div className="field__label">{label}</div>
            <div className="radios-group">
                {datas.map((item, i) => <RadioItem key={i} onChange={onChange} isSelected={ selectedRadio === item.value } name={name} data={item} />)}
            </div>
        </div>
    )
}

export default Radios;