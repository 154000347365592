const CAPACITIES = [
    {
        label:'Aucune',
        value: '0'
    },
    {
        label:'25cl',
        value: '0.25'
    },
    {
        label:'33cl',
        value: '0.33'
    },
    {
        label:'37,5cl',
        value: '0.375'
    },
    {
        label:'50cl',
        value: '0.5'
    },
    {
        label:'70cl',
        value: '0.7'
    },
    {
        label:'75cl',
        value: '0.75'
    },
    {
        label:'100cl',
        value: '1'
    },
    {
        label:'150cl',
        value: '1.5'
    },
    {
        label:'300cl',
        value: '3'
    },
    {
        label:'500cl',
        value: '5'
    }
];

export default CAPACITIES;