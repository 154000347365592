const COLORS = [
    {
        label: 'Aucun',
        value: ''
    },
    {
        label: 'Blanc',
        value: '/img/icon-vin-blanc.png'
    },
    {
        label: 'Rouge',
        value: '/img/icon-vin-rouge.png'
    },
    {
        label: 'Rosé',
        value: '/img/icon-vin-rose.png'
    }
]

export default COLORS;