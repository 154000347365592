import React from 'react';

const Input = ({label, name, placeholder, onChange}) => {
    return(
        <div className="field">
        <label className="field__label">{label}</label>   
        <input name={name} onChange={onChange} placeholder={placeholder} />
        </div>
    )
}

export default Input;