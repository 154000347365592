import React from 'react';

import CATEGORIES from "../datas/categories";

const sanitizePrice = (price) => {

    if (price) {
        if (isNaN(price)) {
            let p = price.toString().replace(',', '.');
            return parseFloat(p)
        }
        return parseFloat(price)
    }
}

const formatPrice = (price) => {
    let p = sanitizePrice(price);

    if (p) {
        const newPrice = new Intl.NumberFormat('fr-FR', {style: 'currency', currency: 'EUR'}).format(p);
        return newPrice;
    }
}

const calculatePrice = (price, capacity) => {
    let p = sanitizePrice(price)

    const priceCalculated = parseFloat(p /  parseFloat(capacity));

    return formatPrice(priceCalculated);
}


const Preview = (props) => {

    const multiLineText = (text) => {
        return text.split('\n').map((str, i) => <p key={i}>{str}</p>)
    }

    const {category, format, producer, name, year, appellation, quote, capacity, price, labels, wineColor} = props;

    const detailPrice = formatPrice(price);
    const pricePerLitre = calculatePrice(price, capacity);

    return (

        <div id="outer-preview">
            <div id="preview" style={{backgroundColor: CATEGORIES[category].value}} className={`preview ${format}`}>

                {wineColor && wineColor.length && <div className="winecolor"><img src={wineColor}/></div>}
                {<p className="category">{CATEGORIES[category].label}</p>}
                {producer && producer.length && <div className="producer">{multiLineText(producer)}</div>}
                {name && name.length && <div className="cuvee">{multiLineText(name)}</div>}
                <div className="center-infos">
                    {year && year.length && <div className="year">{year}</div>}
                    {appellation && appellation.length && <div className="appellation">{appellation}</div>}
                </div>
                <div className="quote-ctn">
                    {quote && quote.length && <div className="quote">{multiLineText(quote)}</div>}
                </div>

                {price && <div className="price"><span className="global">{detailPrice}</span>
                    { capacity !== '0' && <span className="detail">/blle soit {pricePerLitre} le litre</span>}
                </div>}
                <div className="labels">{labels.map((label, i) => <img key={i} src={label}/>)}</div>
                <div className="logo-cash-vin"><img src="/img/logo-cashVin.png"/></div>
            </div>
        </div>

    )
}

export default Preview;