import React from 'react';
import {generateID} from '../../tools';

const RadioItem = ({data, name, isSelected, onChange}) => {
    const id = generateID();
    return(
        <span className="radios-group__item">
            <input type="radio" name={name} id={id} onChange={onChange} value={data.value} checked={ isSelected } />
            <label htmlFor={id}>{data.label}</label>
        </span>

    )
}

export default RadioItem;