import React from 'react';

import CheckboxItem from './CheckboxItem';

const Checkboxes = ({label, datas, name, onChange}) => {
    return(
        <div className="field">
            <label className="field__label">{label}</label>
            <div className="checkbox-group">{datas.map((item,i) => <CheckboxItem data={item} key={i} name={name} onChange={onChange} /> )}</div>
        </div>

    )
}

export default Checkboxes; 