import React from 'react';

const Select = ({onChange, datas, name, label, withDots}) => {

    const displayDot = (item) => {
        if(withDots) {
            return <span style={{background: item}}></span>
        }
    }

    return (
        <div className="field">
            <label className="field__label">{label}</label>
            <select name={name} onChange={onChange}>
                {datas.map((item, i) => {

                        const dot = displayDot(item.value)
                        return <option key={i} value={i}> {item.label}</option>

                    }
                )}
            </select>
        </div>
    )
}

export default Select;